.login-header {
    font-size: 1.3rem;
    &__logo {
        width: 16rem;
        margin: 1.5em auto 3em;
        display: block;

        img {
            max-width: 100%;
        }
    }
}
