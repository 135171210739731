.copyrights {
    font-size: 12px;
    padding-top: 30px;
    margin: auto;
    width: fit-content;
    color: rgb(124, 124, 124);
    a {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.link {
    color: #3594ff;
}
