.expanded-container {
    display: flex;
    border-bottom: 1px dotted gray;
    width: 80%;
    margin-left: 20px;
    .expanded-title {
        font-weight: bold;
        margin-bottom: 20px;
    }
}
.expanded-data {
    font-size: 12px;
    margin-left: 20px;
}

.expanded-container:first-child {
    margin-top: 20px;
}

//.expanded-container:last-child{
//  border-bottom: none;
//}
