@import '../../../../../assets/styles/colors';

.tag-filter-container {
    z-index: 100000000;
    // box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 24%);
    // border: solid 0.5px $cp-greyscale-700;
    border-radius: 4px;
    background-color: $cp-greyscale-100;
    min-width: 185px;
    max-height: 400px;
    max-width: 380px;
    padding: 5px 15px;
    cursor: default;
    color: $cp-greyscale-800;

    .tag-label {
        font-weight: 400;
        font-size: 14px;
        margin-top: 2px;
        margin-bottom: 5px;
        display: inline-block;
    }

    .input-container {
        margin-bottom: 10px;
    }

    .tag-list-container {
        .key-container {
            margin-right: 10px;
        }

        .value-container {
            cp-icon {
                margin-left: 10px;
                font-size: 1rem;
                cursor: pointer;
            }
        }

        cp-text-button {
            cursor: pointer;
            font-size: 1rem;
        }
    }
}
