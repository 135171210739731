.tree-grid-container {
    z-index: 100000000;
    border-radius: 4px;
    background-color: #fff;
    min-width: 185px;
    max-height: 400px;
    max-width: 380px;
    padding: 5px 15px;
    cursor: default;
    color: #7c7c7c;

    .ag-theme-alpine {
        height: 300px !important;
    }
}
.TreeFilter {
    .ag-theme-alpine.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checkbox-input-wrapper {
        box-sizing: content-box;
    }
    .ag-row:not(.ag-row-group) .ag-cell {
        padding-left: 0;
    }
}
