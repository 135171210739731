@import '../../../assets/styles/colors';
.card {
    @apply flex;
}
.WelcomePage {
    background-color: $cp-greyscale-100;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    width: 100%;
    position: relative;
    background-attachment: fixed;
    min-height: 100%;

    .media__subtitle {
        white-space: pre-line;
    }

    .welcome-page-header {
        margin-bottom: 15px;
        .welcome-page-title {
            color: $cp-greyscale-860;
            font-size: 35px;
            text-transform: uppercase;
            padding-bottom: 5px;
            font-weight: 300;
        }
    }
    .welcome-page-section {
        margin-top: 40px;
        .section-header {
            margin-bottom: 25px;
            .section-title {
                color: $cp-greyscale-860;
            }
        }

        .section-items {
            flex-flow: wrap;
            .card-item {
                width: 320px;
                margin: 0px 20px 20px 0px;
                &-button {
                    font-size: 12px;
                }
            }
        }
    }
    .text-body-description {
        padding: 0;
        color: $cp-greyscale-860;
        font-weight: 300;
        letter-spacing: 0;
        margin-bottom: 25px;
        font-size: 13px;
    }
}
