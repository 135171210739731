.count-box {
    min-width: 100px;
    max-width: 250px;
    margin-right: 15px;
    color: #000000cc;

    .number {
        font-size: 28px;
        text-align: center;
        margin-bottom: 5px;
    }

    .text {
        font-size: 10px;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0.8rem;
    }

    .line {
        width: 100%;
        height: 3px;
    }

    &.extend {
        flex: 1;
    }

    &.tiny {
        min-width: 20px;
        width: 27px;
        margin-right: 6px;

        .number {
            font-size: 11px;
            line-height: 18px;
            margin-bottom: 1px;
        }

        .text {
            display: none;
        }
    }

    &.standard {
        min-width: 20px;
        margin-right: 2px;
        flex: 1 1 auto;

        .number {
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
            margin: 20px 6px 6px;
        }

        .text {
            display: none;
        }

        .line {
            height: 5px;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}
