.filter-panel {
    @apply flex flex-1 items-center p-2 rounded-2xl border;
    // > :first-child{
    //     @apply border-l;
    // }
    // > :last-child{
    //     @apply border-r;
    // }
}

.filter-panel__add-btn {
    @apply flex self-stretch;
}
.filter-panel__chips-container {
    @apply flex flex-1 items-center p-[2px] gap-[2px] self-stretch flex-wrap;
}
.filter-panel__free-text-filter {
    @apply self-stretch flex items-stretch z-0;
}
.filter-panel__favorites {
    @apply flex items-stretch;
}
.filter-panel__date-filter {
    @apply flex items-stretch;
}
.filter-panel__clear-all {
    @apply flex items-stretch;
}
