.tabs__list{
  .tabs__item {
    padding: 0 0 !important;
    &:focus, &:active {
      outline: none;
    }
    .paddingFix {
      padding: 8px 20px;
    }
  }
}

