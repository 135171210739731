@import '../../../../../assets/styles/colors';
.menu-button {
    position: relative;
}
.filter-list-menu {
    z-index: 100;
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 24%);
    //border: solid 0.5px $cp-greyscale-600;

    background-color: $cp-greyscale-100;
    min-width: 185px;
    //max-height: 500px;
    height: fit-content;
    width: 150px;
    right: 0;
    top: 30px;
    position: absolute;

    .option {
        display: flex;
        padding: 10px;
        text-transform: capitalize;
    }

    .option:hover {
        background-color: $cp-greyscale-300;
    }
}
