.gsl-builder-modal-container {
    .modal__content {
        background-color: #f9f9f9;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 0 34px;

        .details-second-row {
            position: relative;
            min-height: 300px;
            width: 666px;
            flex-grow: 3;
            background-color: #fff;
            padding: 15px 15px;

            .logic-mode-selection {
                background-color: #fff;
                color: #757575;
                padding: 0 10px 5px;

                cp-radio-button {
                    margin-right: 25px;
                }
            }

            .gsl-logic-container {
                .gsl-input-text-mode {
                    padding: 0 10px;

                    .fl-search {
                        min-height: 245px;
                        display: block;
                        width: 100%;
                        background-color: #f5f5f5;
                        border: none;
                        outline: none;
                        resize: none;
                        padding: 10px;
                        color: #212121;
                    }
                }

                .rule-builder-container {
                    height: auto;

                    .rule-builder-body {
                        height: 100%;
                        min-height: 245px;
                        background-color: #fff;
                        padding: 0 10px 10px 10px;

                        .header-section {
                            height: auto;
                            padding: 0 10px 10px 10px;
                            background-color: #f5f5f5;
                            min-height: 65px;
                        }

                        .content-container {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        .json {
            position: relative;
            display: block;
            width: 250px;
            height: 298px;
            top: 0;
            flex-grow: 1;
            margin-left: 10px;
            overflow: auto;
            background-color: #ffffff;
            padding: 5px;
        }
    }
}
