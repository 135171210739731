.legend-container {
    container-type: inline-size;
    @apply max-h-full;
    &.container--size {
        container-type: size;
    }
    container-name: legend-container;
    @apply flex-1 flex;
}
.legend {
    // grid-cols-[repeat(auto-fit,_minmax(15ch,_1fr))]
    @apply flex-1 grid overflow-auto pr-6 items-start  auto-rows-min;
    @container legend-container (max-height: 90px) {
        @apply text-2xs;
    }
    @container legend-container (min-width: 40ch) {
        @apply grid-cols-2 gap-x-8;
    }
    @container legend-container (aspect-ratio > 3/1) {
    }
}
.legend__item {
    @apply flex grow-0 shrink-0 items-center;
    @container legend-container (max-width: 20ch) {
        @apply text-2xs;
    }
    @container legend-container (max-height: 90px) {
        @apply text-2xs;
    }
    @container legend-container (min-width: 20ch) {
        @apply my-3;
    }
}
.legend__item__name {
    @apply ml-3 truncate;
    @container legend-container (min-width: 20ch) {
        @apply ml-5;
    }
}
.legend__bullet {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    @container legend-container (min-width: 20ch) {
        height: 8px;
        width: 8px;
        border-radius: 4px;
    }
}
.legend__item__value {
    @apply grow-0 shrink-0 font-bold;
    @container legend-container (max-width: 10ch) {
        @apply hidden;
    }
}
.legend__item__spacer {
    @apply flex-1 border-b border-dotted self-center mt-6 mx-2;
    @container legend-container (max-width: 10ch) {
        @apply hidden;
    }
    @container legend-container (min-width: 40ch) {
        @apply mx-7;
    }
}
