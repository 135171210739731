@import '../../../assets/styles/colors';
@import '../../../assets/styles/typography';
.captcha-form {
    height: 100%;
    overflow: auto;

    .recaptcha {
        margin-top: 10px;
    }
    .validation-form {
        padding-top: 10px;

        .validation-form-container {
            margin: 1rem auto;
        }

        .content {
            border: 1px solid $cp-greyscale-400;
            background-color: $cp-greyscale-100;
            padding: 2rem;

            .submit-button {
                justify-self: flex-end;
                float: right;
                margin-top: 10px;
            }
        }

        .email {
            display: block;
        }

        .confirm-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .check-icon {
                font-size: 30px;
            }
            .email-address {
                font-weight: bold;
            }

            h1,
            h3 {
                font-weight: 100;
            }
            h3 {
                margin: 3px;
            }
        }
    }
    .error-message {
        color: red;
    }
}
