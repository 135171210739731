@import '../../../../../assets/styles/colors';

.filter-range-container {
    z-index: 100000000;
    box-shadow: 2px 2px 4px 1px rgb(0 0 0 / 24%);
    border: solid 0.5px $cp-greyscale-600;
    border-radius: 4px;
    background-color: $cp-greyscale-100;
    min-width: 185px;
    max-height: 400px;
    max-width: 380px;
    padding: 10px;
    cursor: default;
}

///silder css
.multi-range-slide-container {
    width: 100%;
    position: relative;

    .slider {
        position: relative;
        width: 100%;
        height: 30px;
    }

    .slider__track,
    .slider__range {
        border-radius: 3px;
        height: 5px;
        position: absolute;
    }

    .slider__track {
        background-color: #ced4da;
        width: 100%;
        z-index: 1;
    }

    .slider__range {
        background: #79c5f7;
        z-index: 2;
    }

    /* Removing the default appearance */
    .thumb,
    .thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }

    .thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
    }

    .thumb--left {
        z-index: 3;
    }

    .thumb--right {
        z-index: 4;
    }

    /* For Chrome browsers */
    .thumb::-webkit-slider-thumb {
        background-color: #79c5f7;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }

    /* For Firefox browsers */
    .thumb::-moz-range-thumb {
        background-color: #79c5f7;
        border: none;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #ced4da;
        cursor: pointer;
        height: 18px;
        width: 18px;
        margin-top: 4px;
        pointer-events: all;
        position: relative;
    }

    .slider__left-value,
    .slider__right-value {
        color: #55637d;
        font-size: 12px;
        margin-top: 20px;
        position: absolute;
    }

    .slider__left-value {
        left: 6px;
    }

    .slider__right-value {
        right: -4px;
    }
}
