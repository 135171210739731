@import '../../../../assets/styles/zIndex';

.filterPanelBox {
    min-width: 185px;
    min-height: 400px;
    max-height: 400px;
    width: 400px;
    top: 42px;
    position: fixed;
    z-index: $z-index-page-dropdown-menu;

    .content_1:not(:empty):after {
        content: '';
        display: block;
        border-bottom-width: 1px;
        border-color: rgba(0, 0, 0, 0.09);
        height: 7px;
        margin: 3px 15px;
    }
}

.filter-panel-container-box {
    .filter-panel {
        min-height: 32px;
        border-radius: 8px;
    }

    .text-field__body {
        height: 32px;
        border-radius: 8px;

        input {
            border-radius: 8px;
        }
    }

    .btn--quiet {
        border: unset;
        border-radius: 8px;
    }

    .seperator {
        margin: 0 8px;
        height: 22px;
        width: 1px;
        background: #d2d9e4;
    }
}
