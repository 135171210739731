.checkbox-filter {
    .clickable-area {
        cursor: pointer;
    }

    .checkbox span {
        margin-left: 0;
    }

    .checkbox > span {
        margin-left: 0.75rem;
    }

    .check-box-label {
        margin-left: 10px;
        width: 90%;
        font-size: 13px;
    }

    .checkbox__input {
        flex-shrink: 0;
    }
    .svg-icon-wrapper {
        display: flex;
    }
}
