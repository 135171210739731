.error-page-container {
    height: 100%;

    .error-page {
        margin: auto;

        .error-page-icon {
            height: 80px;
            width: 80px;

            cp-icon {
                margin: auto;
                font-size: 70px;
            }
        }

        .error-page-title {
            font-size: 32px;
        }

        .error-item {
            padding: 10px;
            max-width: 1000px;
            text-align: center;
        }
    }
}
